import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import RichText from '../components/richtext';
import Carousel from '../components/carousel';

const Project = ({ data }) => {
  const { slug, title, what, client, when, content, images } = data.project;
  const carouselImages = images.map(image => image);

  return (
    <Layout
      dePath={`/design/${slug.current}`}
      enPath={`/en/design/${slug.current}`}
      disableUp
      disableDown
      onClickUp={() => window.fullpage_api.moveSectionUp()}
      onClickDown={() => window.fullpage_api.moveSectionDown()}
    >
      <SEO />
      <StyledWrapper className="dark">
        <StyledSectionText className="dark">
          <h1 className="big headline">{title}</h1>
          <div className="subline">
            <div>
              {what.map((w, i) => (
                <p key={i}>{w}</p>
              ))}
            </div>
            <h2>{client}</h2>
            <p>{when}</p>
          </div>
          <div className="content">
            {content.map((block, i) => (
              <div className="block" key={i}>
                <RichText
                  className="block-headline"
                  blocks={block._rawHeadline}
                />
                <RichText blocks={block._rawDescription} />
              </div>
            ))}
          </div>
        </StyledSectionText>
        <Carousel slides={carouselImages} showArrows loop />
      </StyledWrapper>
    </Layout>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  overflow: hidden;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

const StyledSectionText = styled.section`
  overflow-y: scroll;
  width: 50%;
  height: 90vh;
  padding: var(--spacing-L) var(--spacing-XS) var(--spacing-L) var(--spacing-XS);

  .headline {
    padding-bottom: var(--spacing-M);
  }

  .subline {
    font-family: var(--font-2);
    padding-bottom: var(--spacing-M);
    display: flex;

    div,
    p,
    h2 {
      min-width: 30%;
      padding-right: var(--spacing-XXS);
    }
  }

  .block {
    display: flex;
    padding-bottom: var(--spacing-S);
    p {
      min-width: 30%;
      font-family: var(--font-2);
      padding-right: var(--spacing-XXS);
    }
  }

  @media (max-width: 1020px) {
    overflow: hidden;
    padding: var(--spacing-M) var(--spacing-XXS);
    width: 100%;
    height: auto;
  }
`;

export const query = graphql`
  query($slug: String!) {
    project: sanityDesign(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      what
      client
      when
      content {
        _rawHeadline
        _rawDescription
      }
      images {
        ... on SanityMainImage {
          __typename
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityVideo {
          __typename
          videoUrl
        }
      }
    }
  }
`;

Project.propTypes = {
  data: PropTypes.object,
};

export default localize(Project);
