import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

const Carousel = ({ slides, loop, slidesPerView, spacing, showArrows }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const vidRef = React.useRef();
  const [muted, setMuted] = React.useState(true);
  const [currVideo, setCurrVideo] = React.useState(null);

  const [sliderRef, slider] = useKeenSlider({
    autoHeight: false,
    initial: 0,
    loop,
    slidesPerView,
    spacing,
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
      setCurrVideo(document.querySelector('.inView'));
    },
  });

  const handlePrevSlide = () => slider.prev();
  const handleNextSlide = () => slider.next();

  React.useEffect(() => {
    if (currVideo) currVideo.muted = muted;

    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [currVideo, muted, sliderRef, vidRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 1000000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <>
      <StyledCarousel className="dark">
        <div ref={sliderRef} className="keen-slider">
          {slides.map((slide, i) => (
            <div className="keen-slider__slide" key={i}>
              {(slide.__typename === 'SanityMainImage' && (
                <StyledImg fluid={slide.image.asset.fluid} alt={slide.alt} />
              )) ||
                (slide.__typename === 'SanityVideo' && (
                  <figure>
                    <video
                      src={slide.videoUrl}
                      className={`${i === currentSlide ? 'inView' : 'outView'}`}
                      loop
                      autoPlay
                      muted
                      playsInline
                    />
                  </figure>
                ))}
            </div>
          ))}

          {showArrows && (
            <div className="controls">
              <button
                type="button"
                onClick={handlePrevSlide}
                style={{ cursor: 'w-resize' }}
              ></button>
              <button
                type="button"
                onClick={handleNextSlide}
                style={{ cursor: 'e-resize' }}
              ></button>
            </div>
          )}
        </div>
      </StyledCarousel>

      {currVideo && (
        <StyledMuteBtn>
          <button
            type="button"
            onClick={() => {
              setMuted(!muted);
              currVideo.muted = muted;
            }}
          >
            {muted ? 'sound on' : 'sound off'}
          </button>
        </StyledMuteBtn>
      )}
    </>
  );
};

const StyledMuteBtn = styled.div`
  position: absolute;
  bottom: var(--spacing-L);
  right: var(--spacing-S);
`;

const StyledCarousel = styled.div`
  width: 50%;
  height: 100vh;
  .keen-slider {
    .keen-slider__slide {
      height: 100%;
      margin: auto;
      z-index: 2;
      overflow: visible;

      figure {
        width: 100%;
        height: 100%;

        video {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }

    .controls {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 3;

      button {
        height: 100%;
        width: 50%;
        outline: none;
      }
    }
  }

  @media (max-width: 1020px) {
    padding: 0 var(--spacing-XXS) var(--spacing-XL) var(--spacing-XXS);
    width: 100%;
    height: auto;
  }
`;

const StyledImg = styled(Img)`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  loop: PropTypes.bool,
  slidesPerView: PropTypes.number,
  spacing: PropTypes.number,
  showArrows: PropTypes.bool,
};

Carousel.defaultProps = {
  loop: true,
  slidesPerView: 0,
  spacing: 0,
  showArrows: false,
};

export default Carousel;
